/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($deviceID: ID!) {
    getDevice(deviceID: $deviceID) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $deviceID: ID
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      deviceID: $deviceID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($deviceID: ID!) {
    getLocation(deviceID: $deviceID) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $deviceID: ID
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      deviceID: $deviceID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceID
        lat
        lng
        alt
        description
        device {
          deviceID
          deviceName
          description
          deviceType
          deviceGroup
          serialNo
          swVersion
          hwVersion
          ipChgStatus
          ethIpAddress
          ethSubnet
          ethGateway
          ethDns
          swVerUpTrigger
          tallyIN {
            nextToken
            __typename
          }
          tallyOUT {
            nextToken
            __typename
          }
          location {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTallyIN = /* GraphQL */ `
  query GetTallyIN($deviceID: ID!, $portID: Int!) {
    getTallyIN(deviceID: $deviceID, portID: $portID) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTallyINS = /* GraphQL */ `
  query ListTallyINS(
    $deviceID: ID
    $portID: ModelIntKeyConditionInput
    $filter: ModelTallyINFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTallyINS(
      deviceID: $deviceID
      portID: $portID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceID
        portID
        input
        portName
        description
        device {
          deviceID
          deviceName
          description
          deviceType
          deviceGroup
          serialNo
          swVersion
          hwVersion
          ipChgStatus
          ethIpAddress
          ethSubnet
          ethGateway
          ethDns
          swVerUpTrigger
          tallyIN {
            nextToken
            __typename
          }
          tallyOUT {
            nextToken
            __typename
          }
          location {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTallyOUT = /* GraphQL */ `
  query GetTallyOUT($deviceID: ID!, $portID: Int!) {
    getTallyOUT(deviceID: $deviceID, portID: $portID) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTallyOUTS = /* GraphQL */ `
  query ListTallyOUTS(
    $deviceID: ID
    $portID: ModelIntKeyConditionInput
    $filter: ModelTallyOUTFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTallyOUTS(
      deviceID: $deviceID
      portID: $portID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceID
        portID
        portPWM
        sourceDeviceID
        sourcePortID
        portName
        description
        device {
          deviceID
          deviceName
          description
          deviceType
          deviceGroup
          serialNo
          swVersion
          hwVersion
          ipChgStatus
          ethIpAddress
          ethSubnet
          ethGateway
          ethDns
          swVerUpTrigger
          tallyIN {
            nextToken
            __typename
          }
          tallyOUT {
            nextToken
            __typename
          }
          location {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
